import React, { useState, useEffect, forwardRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  Zoom,
} from "@mui/material"
import { MdSettings, MdClose } from "react-icons/md"
import { FaClock } from "react-icons/fa"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Section from "../../components/section"
import useInterval from "../../functions/use-interval"
import getSiteColor from "../../functions/get-site-color"
import getDayString from "../../functions/get-day-string"
import getMonthString from "../../functions/get-month-string"
import clock from "../../static/clock"
import {
  setNewDate,
  setTimeFormat,
  setClockModalVisibility,
} from "../../states/slices/clock-slice"
import { setCurrentPage } from "../../states/slices/main-slice"
import { playSound } from "../../states/slices/sound-slice"

const Flex = styled.div`
  display: flex;

  &.center__center {
    align-items: center;
    justify-content: center;
  }

  &.center__flex-end {
    align-items: center;
    justify-content: flex-end;
  }

  &.center__space-between {
    align-items: center;
    justify-content: space-between;
  }
`

const ClockContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div.time {
    display: flex;
    align-items: center;
    font-size: 10rem;
    margin: 0;

    div.time-12hour {
      display: flex;
      flex-direction: column;
      margin-left: 0.75rem;

      span {
        font-size: 3rem;

        &.meridiem {
          margin-left: 0.25rem;
          color: ${({ theme, siteColor }) => getSiteColor(siteColor, theme)};
          transition: 0.3s;
        }
      }
    }
  }

  p.date {
    font-size: 2.5rem;
    margin-top: 1rem;
    white-space: nowrap;
  }

  @media (max-width: 991px) {
    div.time {
      font-size: 6rem;

      div.time-12hour {
        margin-left: 0.5rem;

        span {
          font-size: 1.75rem;

          &.meridiem {
            margin-left: 0.2rem;
          }
        }
      }
    }

    p.date {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 400px) {
    div.time {
      font-size: 4rem;

      div.time-12hour {
        margin-left: 0.25rem;

        span {
          font-size: 1.25rem;
        }
      }
    }

    p.date {
      font-size: 1.25rem;
    }
  }
`

const StyledDialog = styled(Dialog)`
  .MuiDialog-container.MuiDialog-scrollPaper {
    .MuiPaper-root {
      ${({ theme }) =>
        theme !== `light` &&
        `
        &.MuiDialog-paperFullScreen {
          background-color: #191919;
        }
      `}
    }
  }
`

const Transition = forwardRef((props, ref) => {
  return <Zoom easing="ease" ref={ref} {...props} />
})

const Clock = () => {
  const { mainReducer, clockReducer } = useSelector(state => {
    return {
      mainReducer: state.mainReducer,
      clockReducer: state.clockReducer,
    }
  })
  const { lang, theme, siteColor } = mainReducer
  const { newDate, timeFormat, clockModalVisibility } = clockReducer
  const dispatch = useDispatch()
  const [settingModal, setSettingModal] = useState(false)

  useEffect(() => {
    dispatch(
      setCurrentPage({
        page: `clock`,
        group: `app`,
      })
    )

    dispatch(setNewDate())
  }, [dispatch])

  useInterval(() => {
    fetchClock()
  }, 1000)

  const fetchClock = () => {
    dispatch(setNewDate())
  }

  const getClock = () => {
    const hr = newDate.hour
    const min = newDate.minute < 10 ? `0${newDate.minute}` : newDate.minute
    let time = ``

    if (timeFormat.twentyFourHourTime === true) {
      time = `${hr < 10 ? `0${hr}` : hr}:${min}`
    } else {
      if (hr > 11) {
        // pm
        time = `${
          hr - 12 < 10 ? (hr - 12 === 0 ? `12` : `${hr - 12}`) : hr - 12
        }:${min}`
      } else {
        // am
        time = `${hr < 10 ? (hr === 0 ? `12` : `${hr}`) : hr}:${min}`
      }
    }

    return time
  }

  const getSecond = () => {
    const sec = newDate.second < 10 ? `0${newDate.second}` : newDate.second

    return sec
  }

  const getMeridiem = () => {
    // am, pm
    const hr = newDate.hour

    return hr > 11 ? clock.time.meridiem.pm[lang] : clock.time.meridiem.am[lang]
  }

  const getLocaleString = () => {
    const dayString = getDayString(newDate.day, `short`, lang)

    const year = newDate.year
    const month = getMonthString(newDate.month, `short`, lang)
    const day = newDate.date

    return lang === `th`
      ? `${dayString}. ${day} ${month} ${year + 543}`
      : `${dayString}, ${month} ${day}, ${year}`
  }

  const getLongString = () => {
    const longDayString = getDayString(newDate.day, `long`, lang)
    const longMonthString = getMonthString(newDate.month, `long`, lang)

    return lang === `th`
      ? `${longDayString}ที่ ${newDate.date} ${longMonthString} ${
          newDate.year + 543
        }`
      : `${longDayString}, ${longMonthString} ${newDate.date}, ${newDate.year}`
  }

  return (
    <Layout>
      <Seo title={clock.pageName[lang]} />
      <Section title={clock.title[lang]} icon={<FaClock />}>
        <Flex className="center__center">
          <ClockContent theme={theme} siteColor={siteColor}>
            <div className="time number">
              {timeFormat.twentyFourHourTime === true ? (
                <span>
                  {getClock()}:{getSecond()}
                </span>
              ) : (
                <>
                  <span>{getClock()}</span>
                  <div className="time-12hour">
                    <span className="meridiem">{getMeridiem()}</span>
                    <span>:{getSecond()}</span>
                  </div>
                </>
              )}
            </div>
            <p className="date">
              {timeFormat.longDate ? getLongString() : getLocaleString()}
            </p>
          </ClockContent>
        </Flex>
        <Flex className="center__flex-end">
          <IconButton
            aria-label="setting"
            color="inherit"
            onClick={() => {
              setSettingModal(true)
              dispatch(playSound(`confirm`))
            }}
          >
            <MdSettings />
          </IconButton>

          {/* <IconButton
            aria-label="full-screen"
            color="inherit"
            onClick={() => {
              dispatch(setClockModalVisibility(true))
              dispatch(playSound(`confirm`))
            }}
          >
            <MdFullscreen />
          </IconButton> */}
        </Flex>
      </Section>

      {/* SETTING MODAL */}
      <StyledDialog
        fullWidth
        maxWidth="xs"
        open={settingModal}
        onClose={() => {
          setSettingModal(false)
          dispatch(playSound(`cancel`))
        }}
        theme={theme}
      >
        <DialogTitle>{clock.time.settings.title[lang]}</DialogTitle>
        <DialogContent dividers>
          <Flex className="center__space-between">
            <span>{clock.time.format.twentyFourHourTime[lang]}</span>
            <Switch
              checked={timeFormat.twentyFourHourTime}
              onChange={() => {
                dispatch(playSound(`confirm`))

                if (timeFormat.twentyFourHourTime === true) {
                  dispatch(
                    setTimeFormat({
                      ...timeFormat,
                      twentyFourHourTime: false,
                    })
                  )
                } else {
                  dispatch(
                    setTimeFormat({
                      ...timeFormat,
                      twentyFourHourTime: true,
                    })
                  )
                }
              }}
              color="primary"
              name="change24HourFormat"
              inputProps={{ "aria-label": "change-24-hour-format" }}
            />
          </Flex>
          <Flex className="center__space-between">
            <span>{clock.time.format.longDate[lang]}</span>
            <Switch
              checked={timeFormat.longDate}
              onChange={() => {
                dispatch(playSound(`confirm`))

                if (timeFormat.longDate === true) {
                  dispatch(
                    setTimeFormat({
                      ...timeFormat,
                      longDate: false,
                    })
                  )
                } else {
                  dispatch(
                    setTimeFormat({
                      ...timeFormat,
                      longDate: true,
                    })
                  )
                }
              }}
              color="primary"
              name="changeDateFormat"
              inputProps={{ "aria-label": "change-date-format" }}
            />
          </Flex>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              transition: `0.3s`,
            }}
            onClick={() => {
              setSettingModal(false)
              dispatch(playSound(`cancel`))
            }}
            color="primary"
          >
            {clock.time.settings.closeButton[lang]}
          </Button>
        </DialogActions>
      </StyledDialog>

      {/* FULL SCREEN MODAL */}
      <StyledDialog
        fullScreen
        open={clockModalVisibility}
        onClose={() => {
          dispatch(setClockModalVisibility(false))
          dispatch(playSound(`cancel`))
        }}
        TransitionComponent={Transition}
        theme={theme}
      >
        <DialogActions
          style={{
            position: `absolute`,
            top: 0,
            right: 0,
            zIndex: 99,
          }}
        >
          <IconButton
            onClick={() => {
              dispatch(setClockModalVisibility(false))
              dispatch(playSound(`cancel`))
            }}
            color="inherit"
          >
            <MdClose />
          </IconButton>
        </DialogActions>
        <DialogContent
          style={{
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
          }}
        >
          <ClockContent theme={theme} siteColor={siteColor}>
            <div className="time number">
              {timeFormat.twentyFourHourTime === true ? (
                <span>
                  {getClock()}:{getSecond()}
                </span>
              ) : (
                <>
                  <span>{getClock()}</span>
                  <div className="time-12hour">
                    <span className="meridiem">{getMeridiem()}</span>
                    <span>:{getSecond()}</span>
                  </div>
                </>
              )}
            </div>
            <p className="date">
              {timeFormat.longDate ? getLongString() : getLocaleString()}
            </p>
          </ClockContent>
        </DialogContent>
      </StyledDialog>
    </Layout>
  )
}

export default Clock
